import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ava-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  redirect() {
    // todo - Get the location from a configuration
    window.location.href = window.location.origin+'/#/'+'login';
  }

}
