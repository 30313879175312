// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  OKTAAUTH_ISSUER: 'https://avalara.okta.com/oauth2/default',
  OKTAAUTH_CLIENT_ID: '0oa1n921yidfNMNUE1d8',
  OKTAAUTH_REDIRECT_URL: 'https://mycontentportal.ci.avalara.io/app/login'
  
  // OKTAAUTH_ISSUER: 'https://dev-73668149.okta.com/oauth2/default',
  // OKTAAUTH_CLIENT_ID: '0oa4n52u1jTkb8uyv5d7',
  // OKTAAUTH_REDIRECT_URL: 'https://localhost:5500/app/login'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
