import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { AppCustomPreloader } from './app-custom-preloader';
import { SignOutComponent } from './sign-out/sign-out.component';
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app'
  },
  {
    path: 'signout',
    component: SignOutComponent
  },
  {
    path: 'app',
    loadChildren: () => import('./main-app/main-app.module').then((m) => m.MainAppModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: AppCustomPreloader,
  useHash: false
};


@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [AppCustomPreloader]
})
export class AppRoutingModule {
  constructor() {
    console.log("App routing module");
  }
}
